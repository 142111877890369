html,
body {
	margin: 0 !important;
	padding: 0 !important;
	overflow-x: hidden !important;
}

.MuiTimeline-root,
.MuiTimeline-positionAlternate,
.css-55vwbm-MuiTimeline-root {
	padding: 0 !important;
	margin: 0 !important;
}

.css-11lq3yg-MuiGrid-root {
	justify-content: center;
}
